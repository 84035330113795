import { useState, useEffect } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import TextField from '@mui/material/TextField'

import { getContentNiceName } from 'src/components/HubDash/CardSettingsDrawer/LinkLearningContent/LinkLearningContentDialog'
import { LinkedResourceType } from 'src/components/HubDash/HubDashLayoutListCell/HubDashLayoutListCell'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import LinkedContentBadge, {
  type LinkedResourceRow,
  LinkedRowList,
} from 'src/components/Library/LinkedContentBadge/LinkedContentBadge'
import Modal from 'src/components/Modal'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import FormInputRow from '../../Forms/FormInputRow/FormInputRow'
import IconSelector from '../../IconSelector/IconSelector'
import Button from '../../Library/Button/Button'
import Switch from '../../Library/Switch/Switch'

// CREATE A CARD MODAL
export const CardAdminModal = ({
  selectedSection,
  informerSections,
  membershipGroups,
  members,
  cardAdminModalOpen,
  setCardAdminModalOpen,
  modalAction,
  setModalAction,
  submitCardForm,
  currentlySaving,
  currentlyDeleting,
  selectedCard,
  setSelectedCard,
  deleteCardItem,
  hideSectionOption = false,
  hideMemberOption = false,
  hideGroupOption = false,
  globalIcons = [],
  isGlobalIcon = false,
}) => {
  // GA TRACKING
  const { trackEvent } = useAnalytics()

  const confirmDeleteCard = useConfirm()

  // Classes for input fields
  const inputClasses =
    'max-w-lg shadow-sm block w-full focus:ring-[#519dfd] focus:border-[#519dfd] sm:text-sm border border-gray-300 rounded-md h-8 p-3 py-4'

  const selectFromGroups = membershipGroups.map((group) => {
    return { id: group.id, name: group.name }
  })

  const selectFromSections = informerSections.map((section) => {
    return { id: section.id, name: section.name }
  })

  const selectFromMembers = [...members]
    .sort((a, b) => (a?.user?.name > b?.user?.name ? 1 : -1))
    .map((member) => {
      return { id: member.id, name: member.user.name }
    })

  // Name
  const [newCardName, setNewCardName] = useState('')
  const handleCardNameChange = (event) => {
    setNewCardName(event.target.value)
  }
  // Link
  const [newCardLink, setNewCardLink] = useState('')
  const [isEmbed, setisEmbed] = useState(false)
  const handleCardLinkChange = (event) => {
    setNewCardLink(event.target.value)
  }
  // Order
  const [newCardOrder, setNewCardOrder] = useState(null)
  const handleCardOrderChange = (event) => {
    setNewCardOrder(event.target.value)
  }
  // Groups
  const [groupValue, setGroupValue] = useState([])
  const [groupInputValue, setGroupInputValue] = useState('')

  // Members
  const [memberValue, setMemberValue] = useState([])
  const [memberInputValue, setMemberInputValue] = useState('')

  // Sections
  const [inputSelectedSection, setInputSelectedSection] = useState({
    id: selectedSection.id,
    name: selectedSection.name,
  })
  const [inputSelectedSectionValue, setInputSelectedSectionValue] = useState(
    selectedSection.name,
  )

  // Image Upload
  const [iconStorageObject, setIconStorageObject] = useState()
  const [iconUploadingStatus, setIconUploadingStatus] = useState(false)

  // linkedWorkflowsRows
  const [linkedWorkflowsRows, setLinkedWorkflowsRows] = useState<
    LinkedResourceRow[]
  >([])

  // Reset the form fields for Create Card Modal
  const resetFormFields = () => {
    setNewCardName('')
    setNewCardLink('')
    setNewCardOrder('')
    setIconStorageObject(null)
    setGroupValue([])
    setGroupInputValue('')
    setMemberValue([])
    setMemberInputValue('')
    setInputSelectedSection({
      id: selectedSection?.id,
      name: selectedSection?.name,
    })
    setInputSelectedSectionValue('')
    setisEmbed(false)
  }

  useEffect(() => {
    if (selectedCard) {
      // Find the assigned groups
      // Convert them to a readable format for the Select Input
      const assignedGroupsOnCurrentCard = selectedCard?.membershipGroups.map(
        (group) => {
          const addGroup = { id: group.membershipGroupId, name: '' }
          membershipGroups.forEach((item) => {
            if (item.id === group.membershipGroupId) {
              addGroup.name = item.name
            }
          })
          // Return found Item
          return addGroup
        },
      )

      const assignedSectionOnCurrentCard = selectFromSections.find(
        (section) => section.id === selectedCard.informerSectionId,
      )

      // Find the assigned members
      // Convert them to readable format for the Select Input
      const assignedMembershipsOnCurrentCard = selectedCard?.members.map(
        (member) => {
          const addMember = { id: member.membershipId, name: '' }
          members.forEach((item) => {
            if (item.id === member.membershipId) {
              addMember.name = item.user.name
            }
          })
          // Return found member
          return addMember
        },
      )

      // Set field values
      if (modalAction === 'duplicate') {
        setNewCardName('Copy - ' + selectedCard?.name)
      } else {
        setNewCardName(selectedCard?.name)
      }

      setNewCardLink(selectedCard?.url)
      setisEmbed(selectedCard?.isEmbed)
      setNewCardOrder(selectedCard?.order)
      setGroupValue(assignedGroupsOnCurrentCard)
      setMemberValue(assignedMembershipsOnCurrentCard)
      setInputSelectedSection(assignedSectionOnCurrentCard)
      setIconStorageObject(selectedCard?.iconStorageObject)
    }
  }, [selectedCard])

  // Clean up on modal close
  useEffect(() => {
    if (!cardAdminModalOpen) {
      // Closing Modal
      resetFormFields()
      setSelectedCard(null)
      setModalAction('')
    } else {
      // Opening Modal
      // If mode is create, set the section default as current
      if (modalAction === 'create') {
        setInputSelectedSection(selectedSection)
        setInputSelectedSectionValue(selectedSection.name)
      }
    }
  }, [cardAdminModalOpen])

  return (
    <Modal
      open={cardAdminModalOpen}
      closeButtonVisible={false}
      className="max-w-2xl !p-0"
      dialogClassName="!bg-white !p-0"
      onClose={() => {}}
    >
      <div id="createCardModal">
        <div className="flex items-center justify-between px-6 py-4 pt-3">
          <h3 className="mb-0 text-lg font-medium leading-6 text-gray-900">
            {modalAction === 'create' && <span>Creating new card</span>}
            {modalAction === 'edit' && (
              <span>
                Editing card: &nbsp;
                <span className="text-indigo-500">{selectedCard.name}</span>
              </span>
            )}
            {modalAction === 'duplicate' && (
              <span>
                Duplicating card: &nbsp;
                <span className="text-indigo-500">{selectedCard.name}</span>
              </span>
            )}
          </h3>
          <Button
            fullWidth={false}
            variant="text"
            className="min-w-[0px]"
            onClick={() => setCardAdminModalOpen(false)}
            disabled={currentlyDeleting || currentlySaving}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </Button>
        </div>

        <form
          id=""
          className="border-t border-gray-200"
          onSubmit={(e) => {
            e.preventDefault()
            submitCardForm({
              data: {
                name: newCardName,
                link: newCardLink,
                isEmbed: isEmbed,
                iconStorageObject: iconStorageObject,
                order: newCardOrder,
                groups: groupValue,
                members: memberValue,
                section: inputSelectedSection?.id,
              },
            })
          }}
          autoComplete="off"
        >
          <div className="p-6">
            <FormInputRow label={'cardName'} title={'Name'}>
              <TextField
                className="w-full"
                fullWidth
                size="small"
                autoComplete="false"
                id="informerListCardNameInput"
                type="text"
                name="cardName"
                required
                value={newCardName}
                onChange={handleCardNameChange}
              />
            </FormInputRow>
            <FormInputRow label={'cardLink'} title={'Link'}>
              <TextField
                className="w-full"
                fullWidth
                size="small"
                autoComplete="false"
                id="informerListCardLinkInput"
                type="text"
                name="cardLink"
                required
                value={newCardLink}
                onChange={handleCardLinkChange}
              />
            </FormInputRow>
            <FormInputRow label={'cardIsEmbed'} title={'Embedded'}>
              <Switch
                data-action="pin-section"
                checked={isEmbed}
                onChange={(e) => {
                  setisEmbed(e.target.checked)
                }}
              />
              {isEmbed && (
                <p className="pt-3 text-xs text-gray-500">
                  <b>Note:</b> This link will open inside TheHub application as
                  an embedded page. The hosting site of this url will need to
                  allow embedding for this to work, otherwise the page will not
                  load.
                </p>
              )}
            </FormInputRow>
            <FormInputRow label={'cardImage'} title={'Logo'}>
              <IconSelector
                selectableIcons={globalIcons}
                onIconSelect={setIconStorageObject}
                setUploadingStatus={setIconUploadingStatus}
                currentIcon={iconStorageObject}
                isGlobalIcon={isGlobalIcon}
              />
            </FormInputRow>
            <input
              autoComplete="false"
              id="informerListCardOrderInput"
              type="hidden"
              name="cardOrder"
              value={newCardOrder ?? ''}
              onChange={handleCardOrderChange}
              className={inputClasses}
            />
            {!hideSectionOption && (
              <FormInputRow label={'cardSection'} title={'Section'}>
                <Autocomplete
                  className="w-full"
                  fullWidth
                  size="small"
                  value={inputSelectedSection}
                  onChange={(_event, newValue) => {
                    setInputSelectedSection(newValue)
                  }}
                  inputValue={inputSelectedSectionValue}
                  onInputChange={(_event, newInputValue) => {
                    setInputSelectedSectionValue(newInputValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  id="informerListCardAssignSectionInput"
                  options={selectFromSections}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                  disableClearable
                />
              </FormInputRow>
            )}
            {!hideGroupOption && (
              <FormInputRow label={'cardGroups'} title={'Select Groups'}>
                <Autocomplete
                  className="w-full"
                  fullWidth
                  size="small"
                  value={groupValue}
                  disableCloseOnSelect
                  filterSelectedOptions
                  multiple
                  onChange={(_event, newValue) => {
                    setGroupValue(newValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  inputValue={groupInputValue}
                  onInputChange={(_event, newInputValue) => {
                    setGroupInputValue(newInputValue)
                  }}
                  id="informerListCardAssignGroupInput"
                  options={selectFromGroups}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormInputRow>
            )}
            {!hideMemberOption && (
              <FormInputRow
                label={'cardMembers'}
                title={'Select Members'}
                note={
                  <>
                    When both groups and members have values, the card will be
                    visible to all members of the selected groups and members.
                    <br />
                    <br /> When no groups or members are selected, the card will
                    be visible to all.
                  </>
                }
              >
                <Autocomplete
                  className="w-full"
                  fullWidth
                  size="small"
                  value={memberValue}
                  disableCloseOnSelect
                  filterSelectedOptions
                  multiple
                  onChange={(_event, newValue) => {
                    setMemberValue(newValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  inputValue={memberInputValue}
                  onInputChange={(_event, newInputValue) => {
                    setMemberInputValue(newInputValue)
                  }}
                  id="dialogInputMembersAll"
                  options={selectFromMembers}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormInputRow>
            )}
            <div className="ml-auto w-fit">
              <LinkedContentBadge
                resourceId={selectedCard?.id}
                resourceType={LinkedResourceType.HOME_LINK}
                returnLinkedContent={setLinkedWorkflowsRows}
              />
            </div>
          </div>

          <div className="flex flex-row-reverse items-center justify-between bg-gray-100 px-6 py-4">
            <div className="flex gap-4">
              <Button
                variant="text"
                fullWidth={false}
                disabled={
                  currentlyDeleting || currentlySaving || iconUploadingStatus
                }
                onClick={() => setCardAdminModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                fullWidth={false}
                disabled={
                  currentlyDeleting || currentlySaving || iconUploadingStatus
                }
                type="submit"
                loading={currentlySaving}
                buttonDataTestId="informerCardButtonSave"
                data-action={modalAction}
                onClick={() => {
                  trackEvent('Settings', 'save card', {
                    card: selectedCard?.name,
                  })
                }}
              >
                <span>
                  {modalAction === 'create' && 'Create Card'}
                  {modalAction === 'edit' && 'Save Card'}
                  {modalAction === 'duplicate' && 'Duplicate Card'}
                </span>
              </Button>
            </div>
            <div>
              {modalAction === 'edit' && (
                <Button
                  disabled={
                    currentlyDeleting || currentlySaving || iconUploadingStatus
                  }
                  fullWidth={false}
                  className="bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                  onClick={(e) => {
                    e.preventDefault()

                    confirmDeleteCard({
                      title: 'Delete Card',
                      description: (
                        <div>
                          {linkedWorkflowsRows.length > 0 && (
                            <>
                              <div>{`This ${getContentNiceName(LinkedResourceType.HOME_LINK)} is Linked to the following HubDash Cards and Layouts:`}</div>
                              <LinkedRowList
                                linkedWorkflowsRows={linkedWorkflowsRows}
                                noLink
                              />
                            </>
                          )}

                          <div className="mt-4">
                            Are you sure you want to delete this card?
                          </div>
                        </div>
                      ),
                    }).then((isConfirmed) => {
                      if (!isConfirmed) return
                      deleteCardItem(selectedCard?.id)
                    })
                  }}
                  loading={currentlyDeleting}
                >
                  Delete Card
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}
